<template>
  <div>
    <TLoading v-model="loading" />
    <TContainer>
      <div class="mt-8">
        <component :is="headerContent" v-bind:data="dataProperties" />
        <v-card class="px-3 py-3">
          <v-skeleton-loader v-if="loading" :loading="loading" transition="fade-transition" type="table" />
          <v-data-table
            v-if="!loading"
            :headers="selectedHeaders"
            :items="getListFilter"
            :items-per-page="itemsPerPage"
            :page.sync="valuePage"
            item-key="id"
            hide-default-footer
            hide-default-header
            class="text--text"
            :sort-by.sync="pagination.sortBy"
            :sort-desc.sync="pagination.sortDesc"
          >
            <template v-slot:top class="d-flex justify-content-right">
              <component :is="filterContent" />
            </template>

            <template v-slot:header="{ props }">
              <thead>
                <draggable
                  v-model="selectedHeaders"
                  :empty-insert-threshold="true"
                  tag="tr"
                  class="dataTableHeaders"
                  @change="headersOrderUpdated($event)"
                >
                  <th
                    role="columnheader"
                    scope="col"
                    v-for="(header, index) in props.headers"
                    :key="header.name"
                    :style="{ textAlign: header.align || 'left' }"
                    :class="['header-draggable']"
                  >
                    <data-table-header-cell
                      v-bind:pagination.sync="pagination"
                      :header="header"
                      :key="`Header${index}`"
                      @update-order="updateSortObject()"
                    />
                  </th>
                </draggable>
              </thead>
            </template>

            <template v-slot:body="props">
              <tbody v-if="props.items.length">
                <tr
                  v-if="props.items.length && props.headers.filter((el) => el.createFilter).length > 0"
                  class="grey lighten-4"
                >
                  <th v-for="header in props.headers" :id="header.value" :key="header.text" class="pa-2 pb-0">
                    <component v-if="header.customFilter" :is="header.customFilter" :data="getListFilter" />

                    <v-autocomplete
                      v-else-if="header.createFilter && header.filterType != 'date' && !header.customFilter"
                      v-model="filter[header.value]"
                      :items="
                        header.filterOptions
                          ? header.filterOptions.getOptions(getListFilter)
                          : getListFilter
                              .map((el) => el[header.value])
                              .filter((value, index, self) => self.indexOf(value) === index)
                              .filter((el) => el != null)
                              .sort()
                      "
                      outlined
                      clearable
                      dense
                      @input="sendFilter()"
                    />

                    <input-date-filter
                      v-if="header.createFilter && header.filterType == 'date'"
                      v-model="filter[header.value]"
                      :format="'DD/MM/YYYY'"
                      :allowedDates="
                        getListFilter
                          .map((el) => $moment(el[header.value], 'DD/MM/YYYY').format('YYYY-MM-DD'))
                          .filter((value, index, self) => self.indexOf(value) === index)
                          .sort()
                      "
                      readonly
                      dense
                      restrictDates
                      @input="sendFilter()"
                    />
                  </th>
                </tr>
                <tr v-for="(row, rowIndex) in props.items" :key="`rowIndex${rowIndex}`">
                  <td
                    v-for="(header, key) in selectedHeaders"
                    :key="key"
                    :align="header.align || 'left'"
                    :width="header.width"
                    :class="header.class"
                  >
                    <template v-if="header.value == 'actions'">
                      <v-btn
                        depressed
                        fab
                        dark
                        :x-small="!isMobile"
                        :small="isMobile"
                        color="secondary"
                        class="mr-2"
                        @click="editItem(row)"
                      >
                        <v-icon dark> mdi-pencil </v-icon>
                      </v-btn>
                      <v-btn
                        depressed
                        fab
                        dark
                        :x-small="!isMobile"
                        :small="isMobile"
                        color="error"
                        @click="deleteItem(row)"
                      >
                        <v-icon dark> mdi-delete </v-icon>
                      </v-btn>
                    </template>
                    <component
                      v-else
                      :is="header.component || 'DataTableTextColumn'"
                      :key="key"
                      :options="header.options"
                      :height="header.height"
                      :row="row"
                      :value="row[header.value]"
                    />
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td :colspan="headers.length" style="text-align: center">
                    {{ getMessage || $t('noDataAvailable') }}
                  </td>
                </tr>
              </tbody>
            </template>

            <template v-slot:footer>
              <v-container fluid class="mt-10">
                <v-row>
                  <v-col cols="12" sm="12" md="9">
                    <TPagination
                      :label="label"
                      :valueItemsPerPage="itemsPerPage"
                      :items="itemsSelectPerPage"
                      :valuePage="valuePage"
                      :totalLengthList="getListFilter.length"
                      :disabled="loading"
                      :total-visible="10"
                      @change="changePageItems"
                    />
                  </v-col>
                  <v-col cols="12" sm="12" md="3">
                    <data-table-config v-model="selectedHeaders" :items="headers" :table-headers-key="namePage" />
                  </v-col>
                </v-row>
              </v-container>
            </template>
          </v-data-table>
        </v-card>

        <v-fab-transition leave-absolute v-if="addNew">
          <v-btn color="success" fixed fab large dark bottom right @click="redirectAdd">
            <v-icon> mdi-plus </v-icon>
          </v-btn>
        </v-fab-transition>
        <v-fab-transition leave-absolute v-if="getListFilter.length > 0 && enablePrinting">
          <GenerateXLSXButon :data="getListFilter" :columns="columns" :filename="namePage" :sheetname="'Data'">
            <v-btn color="primary" fixed fab large dark bottom right>
              <v-icon>mdi-cloud-download </v-icon>
            </v-btn>
          </GenerateXLSXButon>
        </v-fab-transition>
      </div>
    </TContainer>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import mixinRouter from '@/mixins/mixinRouter'
import mixinAlert from '@/mixins/mixinAlert'
import InputDate from '@/components/InputDate'
import DataTableService from '@/components/data-table/DatatableService'
import DataTableConfig from '@/components/data-table/DataTableConfig.vue'
import Draggable from 'vuedraggable'
import DataTableHeaderCell from './DataTableHeaderCell.vue'
import GenerateXLSXButon from './GenerateXLSXButon.vue'
import InputDateFilter from './InputDateFilter.vue'

export default {
  name: 'ListView',
  mixins: [mixinRouter, mixinAlert],
  components: {
    InputDate,
    Draggable,
    DataTableConfig,
    DataTableHeaderCell,
    GenerateXLSXButon,
    InputDateFilter,
  },
  data() {
    return {
      namePage: '',
      loading: false,
      label: this.$i18n.t('itemsPerPage'),
      itemsPerPage: 10,
      valuePage: 1,
      itemsSelectPerPage: [10, 20, 30],
      selected: [],
      headers: [],
      selectedHeaders: [],
      keyItem: 'id',
      path: '',
      filterContent: null,
      headerContent: null,
      addNew: true,
      dataProperties: {},
      filter: {},
      search: '',
      pagination: {
        sortBy: '',
        sortDesc: '',
        multiSort: false,
      },
      enablePrinting: false,
    }
  },
  computed: {
    columns() {
      if (this.getListFilter.length > 0 && this.selectedHeaders) {
        const keys = Object.keys(this.getListFilter[0])
        let headers = this.selectedHeaders.filter((head) => keys.indexOf(head.value) > -1)
        return headers.map((head) => {
          return { field: head.value, label: head.text }
        })
      }
      return []
    },
    ...mapState({
      getList(_, getters) {
        return getters[this.getPathStore + '/getList']
      },
      getMessage(_, getters) {
        return getters[this.getPathStore + '/getMessage']
      },
      getListFilter(_, getters) {
        return getters[this.getPathStore + '/getListFilter']
      },
      getStoreFilter(_, getters) {
        return getters[this.getPathStore + '/getFilter']
      },
      getPaginationToken(_, getters) {
        return getters['userStore/getPaginationToken']
      },
    }),
    getPathStore() {
      return this.path + 'Store'
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
    getFilter() {
      return {
        active: true,
      }
    },
    getListLength() {
      return this.getList.length
    },
  },
  methods: {
    sendFilter() {
      this.filter = { ...this.getStoreFilter, ...this.filter }
      this.setFilter(this.filter)
    },
    updateSortObject() {
      this.pagination = Object.assign({}, this.pagination)
    },
    ...mapActions({
      setDataToList(dispatch, payload) {
        return dispatch(this.getPathStore + '/setDataToList', payload)
      },
      setDeleteData(dispatch, payload) {
        return dispatch(this.getPathStore + '/setDeleteData', payload)
      },
      setFilter(dispatch, payload) {
        return dispatch(this.getPathStore + '/setFilter', payload)
      },
      clearState(dispatch) {
        return dispatch(this.getPathStore + '/clearState')
      },
    }),
    async getItemsList(paginationToken = null) {
      this.loading = true
      let result = await this.setDataToList({ PaginationToken: paginationToken })

      if (this.path == 'user' && this.getPaginationToken) {
        while (this.getPaginationToken) {
          await this.getItemsList(this.getPaginationToken)
        }
      }
      this.loading = false
      if (result instanceof Error) {
        await this.mixinAlertError()
      }
    },
    async changePageItems(objPageItems) {
      this.valuePage = objPageItems.page
      this.itemsPerPage = objPageItems.itemsPerPage
    },
    editItem(item) {
      let params = {}
      params[this.keyItem] = item[this.keyItem]
      this.redirect({
        name: `${this.path}Update`,
        params,
      })
    },
    async deleteItem(item) {
      let confirm = await this.mixinAlertConfirm()
      if (!confirm) return
      this.mixinAlertLoading()
      let data = {}
      data[this.keyItem] = item[this.keyItem]
      let result = await this.setDeleteData(data)
      if (result instanceof Error) {
        await this.mixinAlertError()
        return
      }
      await this.mixinAlertSuccess()
      this.getItemsList()
    },
    redirectAdd() {
      this.redirect({
        name: `${this.path}Add`,
      })
    },
    headersOrderUpdated() {
      DataTableService.saveHeaders(this.namePage, this.selectedHeaders)
    },
  },
  async mounted() {
    this.clearState()
    await this.getItemsList()
  },
  created() {
    const localHeaders = DataTableService.getHeaders(this.namePage)
    this.$root.$on('clear-input-filters', () => {
      this.filter = {}
    })
    if (localHeaders) {
      this.selectedHeaders = localHeaders
      const newHeaders = localHeaders
        .map((header) => {
          const headerCorrespondente = this.headers.find((item) => item.text === header.text)
          if (!headerCorrespondente) {
            return null
          }
          return {
            ...headerCorrespondente,
            ...header,
          }
        })
        .filter((item) => item !== null)
      this.selectedHeaders = newHeaders
    } else {
      this.selectedHeaders = this.headers
    }
  },
}
</script>
<style scoped>
.sortable-drag {
  color: rgba(0, 0, 0, 0.54) !important;
  font-weight: normal;
  font-family: Roboto, sans-serif !important;
  font-size: 0.85em;
}

.header-draggable {
  cursor: pointer;
}

.orderArrow {
  position: absolute;
  opacity: 0;
}

.inHover {
  opacity: 0.6;
}

.arrowUp {
  opacity: 1;
}

.arrowDown {
  opacity: 1;
  transform: rotate(180deg);
}
</style>
