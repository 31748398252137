import config from '@/config'
import Amplify, { Auth, Hub } from 'aws-amplify'
Amplify.configure({
  Auth: {
    identityPoolId: config.AWS_ID_IDENTITY_POOL,
    region: config.AWS_REGION,
    userPoolId: config.AWS_ID_GROUP_USER_POOL,
    userPoolWebClientId: config.AWS_USER_POOL_ID_CLIENT,
  },
})
const refreshTokenStorage = async () => {
  let accessToken
  let idToken
  try {
    let session = await Auth.currentSession()
    accessToken = session.getAccessToken().getJwtToken()
    idToken = session.getIdToken().getJwtToken()
  } catch {
    accessToken = 'invalid'
    idToken = 'invalid'
  }
  localStorage.setItem(config.TOKEN_STORAGE, accessToken)
  localStorage.setItem(config.ID_TOKEN_STORAGE, idToken)
}

const forceRefresh = async () => {
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser()
    const currentSession = cognitoUser.getSignInUserSession()

    cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
      let accessToken = session.accessToken.jwtToken
      let idToken = session.idToken.jwtToken

      localStorage.setItem(config.TOKEN_STORAGE, accessToken)
      localStorage.setItem(config.ID_TOKEN_STORAGE, idToken)
    })
  } catch (e) {
    localStorage.setItem(config.TOKEN_STORAGE, 'invalid')
    localStorage.setItem(config.ID_TOKEN_STORAGE, 'invalid')
  }
}
const removeTokenStorage = () => {
  localStorage.removeItem(config.TOKEN_STORAGE)
  localStorage.removeItem(config.ID_TOKEN_STORAGE)
}
const listener = (data) => {
  switch (data.payload.event) {
    case 'signIn':
      refreshTokenStorage()
      break
    case 'signOut':
      removeTokenStorage()
      break
    case 'tokenRefresh':
      refreshTokenStorage()
      break
    case 'forceRefresh':
      forceRefresh()
      break
    case 'tokenRefresh_failure':
      removeTokenStorage()
      break
  }
}
Hub.listen('auth', listener)
