<template>
  <ListViewUser />
</template>
<script>
import ListViewUser from '@/components/ListViewUser'
export default {
  components: {
    ListViewUser,
  },
}
</script>
