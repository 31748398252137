export default {
  methods: {
    getMessageError(code) {
      let message = ''
      switch (code) {
        case 'UserNotFoundException':
          message = this.$i18n.t('userDoesNotExist')
          break
        case 'NotAuthorizedException':
          message = this.$i18n.t('incorrectUsernameOrPassword')
          break
        case 'CodeMismatchException':
          message = this.$i18n.t('invalidVerificationCode')
          break
        default:
          message = this.$i18n.t('anUnexpectedErrorHasOccurred')
          break
      }
      return message
    },
  },
}
