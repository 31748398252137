export default {
  methods: {
    redirect(path) {
      this.$router.push(path).catch((error) => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      })
    },
  },
}
