import Vue from 'vue'
import Vuex from 'vuex'

import invoicesStore from './invoices/invoicesStore'
import transportationDocumentStore from './transportationDocument/transportationDocumentStore'
import groupStore from './group/groupStore'
import userStore from './user/userStore'
import occurrencesStore from './occurrences/occurrencesStore'
import companiesConfigStore from './companies/companiesConfigStore'
import fileStore from './files/fileStore'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    invoicesStore,
    companiesConfigStore,
    transportationDocumentStore,
    groupStore,
    userStore,
    occurrencesStore,
    fileStore,
  },
})
