<template>
  <div>
    <t-loading v-model="loading" />
    <t-head :header="getNamePage" :breadcrumb="getBreadcrumb" />
    <t-container>
      <v-card>
        <v-card-text>
          <v-form ref="form" @submit.prevent="() => sendNotes()">
            <v-row>
              <v-col cols="6" md="2">
                <input-date v-model="objDados.from" :label="$t('from')" clearable dense :rules="[rules.required]" />
                <input-date v-model="objDados.to" :label="$t('to')" clearable dense :rules="[rules.required]" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="2">
                <v-autocomplete
                  v-model="objDados.cnpj"
                  :items="companyCodeList"
                  :label="$t('companyCode')"
                  :rules="[rules.required]"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <input
                  type="file"
                  class="none"
                  ref="input"
                  @change="previewFiles"
                  multiple
                  accept="image/png, image/jpg, image/jpeg, application/pdf"
                />
                <v-btn color="primary" :disabled="!objDados.cnpj" @click="() => clear() || $refs.input.click()">
                  {{ $t('chooseFiles') }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                {{ $t('fileNamesDisclaimer') }}
              </v-col>
            </v-row>
            <v-row v-if="!!getFilenames.length">
              <v-col>
                <p>{{ $t('filesToSend') }}:</p>
                <ul>
                  <li v-for="({ filename }, index) in getFilenames" :key="index">
                    {{ filename }}
                  </li>
                </ul>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-btn color="primary" :disabled="shouldDisableTheSend" type="submit">
                  {{ $t('sendFiles') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </t-container>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import mixinRouter from '@/mixins/mixinRouter'
import mixinAlert from '@/mixins/mixinAlert'
import { PDFDocument } from 'pdf-lib'
import utils from '@/utils'
import config from '@/config'
import axios from 'axios'
import UserAttributes from '@/helpers/UserAttributes'
import InputDate from '@/components/InputDate.vue'

export default {
  mixins: [mixinRouter, mixinAlert],
  components: {
    InputDate,
  },
  data() {
    return {
      allowedName: 'nebel',
      allowedEmails: ['pelissari.celso@tigre.com', 'Amandanebel@gmail.com', 'amandanebel@gmail.com'],
      nfePayloads: [],
      details: [],
      objDados: {
        from: null,
        to: null,
        cnpj: null,
        files: {
          images: [],
          pdfs: [],
        },
      },
      totalFiles: 0,
      rules: {
        required: (value) => !!value || this.$i18n.t('required'),
      },
      loading: false,
    }
  },
  computed: {
    ...mapState({
      getCompanyCodeList(_, getters) {
        return getters['userStore/getCompanyCodeList']
      },
    }),
    getFilenames() {
      return [
        ...this.objDados.files.images.map(({ name }) => ({ filename: name })),
        ...this.objDados.files.pdfs.map(({ filename }) => ({ filename })),
      ]
    },
    companyCodeList() {
      let chave = 'companyCode'
      return this.getCompanyCodeList.map((el) => ({
        text: el[chave] || el.companyCode,
        value: el.companyCode,
      }))
    },
    shouldDisableTheSend() {
      return (
        this.totalFiles === 0 && this.totalFiles === this.objDados.files.images.length + this.objDados.files.pdfs.length
      )
    },
    getNamePage() {
      return this.$i18n.t('massDelivery')
    },
    getBreadcrumb() {
      return this.$i18n.t('massDelivery')
    },
  },
  methods: {
    ...mapActions('invoicesStore', { getInvoices: 'setDataToList' }),
    ...mapActions('userStore', {
      setCompanyCodeDataToList: 'setCompanyCodeDataToList',
    }),
    async sendNotes() {
      let confirm = await this.mixinAlertConfirm()
      if (!confirm) return
      this.mixinAlertLoading(this.$t('dontCloseWindow'))
      try {
        await Promise.all([...this.uploadImages(), ...this.uploadPdfs()])
        await this.requestTranslation()
      } catch (error) {
        console.log(error)
        await this.mixinAlertError(error.data && error.data.message)
        return
      }
    },
    async requestTranslation() {
      const filenames = this.getFilenames
      const response = await Promise.all(
        filenames.map(async ({ filename }) => {
          try {
            const request = await axios.post(
              config.AWS_TEXTRACT_SERVICE,
              { filename, bucket: config.AWS_TEXTRACT_BUCKET },
              { ...utils.makeTextractRequestData() }
            )
            return {
              filename,
              request,
              isError: false,
            }
          } catch (error) {
            return {
              filename,
              request: error,
              isError: true,
            }
          }
        })
      )
      let nfes = await this.analyzeResponse(response.filter((res) => !res.isError))
      if (!nfes.length) {
        this.mixinAlertError(this.$t('noNumbersDetected'))
        return
      }
      nfes = utils.simplifyArrayOfObjects(nfes)
      let requests = []
      for (let key of Object.keys(nfes)) {
        requests = [
          ...requests,
          ...nfes[key].map(async (nfe) => {
            const filters = {
              companyCode: this.objDados.cnpj,
              creationStartTime: `${this.objDados.from}T00:00:00-03:00`,
              creationFinishTime: `${this.objDados.to}T23:59:59-03:00`,
              nfNumber: nfe,
            }
            try {
              const invoices = await this.getInvoices(filters)
              return { data: invoices.data, isError: false }
            } catch (error) {
              return { data: error, isError: true }
            }
          }),
        ]
      }
      const responses = await Promise.allSettled(requests)
      let payloads = []
      let fileSubmitsCount = {}
      await Promise.all(
        responses
          .filter(({ status }) => status === 'fulfilled')
          .map(async (res) => {
            if (res.value.data) {
              const [invoice] = res.value.data.invoices
              const file = this.getFileFromNfeNumber(invoice.documentCode, nfes)
              const fileData = await utils.convertToBase64(file.content)
              const payload = {
                base64String: fileData,
                nfe: invoice.documentCode,
                cte: invoice.transportationCode,
                cnpj: invoice.transportCompanyCode,
                fileName: file.filename,
              }
              if (fileSubmitsCount[file.filename]) {
                fileSubmitsCount[file.filename] += 1
              } else {
                fileSubmitsCount[file.filename] = 1
              }
              payloads = [...payloads, payload]
            }
          })
      )
      this.nfePayloads = payloads
      const filesWithSubmits = Object.keys(fileSubmitsCount)
      const filesWithoutSubmits = utils.diffArray(
        filesWithSubmits,
        this.getFilenames.map((file) => file.filename)
      )
      if (payloads.length === 0) {
        this.mixinAlertError(this.$t('noNumbersDetected'))
        return
      }

      const group = this.groupBy(payloads, 'base64String')
      let payload = []
      Object.keys(group).forEach((key) => {
        payload.push({
          base64String: key,
          data: group[key].map((el) => {
            return { cnpj: el.cnpj, cte: el.cte, fileName: el.fileName, nfe: el.nfe }
          }),
        })
      })

      await axios.post(
        `${config.BACKEND_URL}/file`,
        { multiple: payload },
        {
          ...utils.makeBackendRequestData(),
        }
      )

      this.mixinAlertSuccess()
      this.mixinAlertSuccess(
        this.$t(filesWithoutSubmits.length > 0 ? 'fileUploadConcludedWithFilesUnsubmitted' : 'fileUploadConcluded', {
          files: filesWithoutSubmits.join(', '),
        })
      )
    },
    getFileFromNfeNumber(number, nfes) {
      let fileName

      for (let file of Object.keys(nfes)) {
        const index = nfes[file].findIndex((nfe) => nfe === number)
        if (index >= 0) {
          fileName = file
          break
        }
      }
      if (!fileName) return
      if (fileName.slice(-4) === '.pdf') {
        return this.objDados.files.pdfs.find((pdf) => pdf.filename === fileName)
      }
      return { content: this.objDados.files.images.find((image) => image.name === fileName), filename: fileName }
    },
    groupBy(array, property) {
      return array.reduce((grouped, element) => {
        return {
          ...grouped,
          [element[property]]: [...(grouped[element[property]] || []), element],
        }
      }, {})
    },
    analyzeResponse(response) {
      return Promise.all(
        response.map(async ({ request, filename }) => await this.getNFeFromReading(filename, request.data))
      )
    },
    async getNFeFromReading(filename, reading) {
      return new Promise((resolve) => {
        const texts = reading.filter((read) => read['BlockType'] === 'LINE').map((reads) => reads['Text'])
        let nfes = []
        const regex = /\d{9}/g
        texts.forEach((text) => {
          const matches = text.match(regex)
          if (matches) {
            nfes = [...nfes, ...matches]
          }
        })
        nfes = [...new Set(nfes)]
        resolve({
          [`${filename}`]: nfes,
        })
      })
    },
    uploadImages() {
      const s3 = utils.s3({
        region: 'us-east-1',
      })
      if (this.objDados.files.images.length) {
        return this.objDados.files.images.map(async (image) => {
          return await s3
            .upload({
              Bucket: config.AWS_TEXTRACT_BUCKET,
              Key: image.name,
              Body: image,
            })
            .promise()
        })
      }
      return []
    },
    uploadPdfs() {
      const s3 = utils.s3({
        region: 'us-east-1',
      })
      if (this.objDados.files.pdfs.length) {
        return this.objDados.files.pdfs.map(async (pdf) => {
          return await s3
            .upload({
              Bucket: config.AWS_TEXTRACT_BUCKET,
              Key: pdf.filename,
              Body: pdf.content,
            })
            .promise()
        })
      }
      return []
    },
    async previewFiles(event) {
      this.loading = true
      this.totalFiles = event.target.files.length
      const files = Array.from(event.target.files)
      await this.handleFiles(files)
      this.loading = false
    },
    async handleFiles(files) {
      return Promise.all(
        files.map(async (file) => {
          if (file.type !== 'application/pdf') {
            this.objDados.files.images.push(file)
            return
          }
          const pdf = await PDFDocument.load(await utils.readFile(file))
          const pages = pdf.getPages()
          if (pages.length > 1) {
            this.totalFiles += pages.length - 1
            for (let i = 0; i < pages.length; i++) {
              const pdfDoc = await PDFDocument.create()
              const [copiedPage] = await pdfDoc.copyPages(pdf, [i])
              pdfDoc.insertPage(0, copiedPage)
              const { filename, extension } = this.divideFilenameAndExtension(file.name)
              this.objDados.files.pdfs.push({
                content: await pdfDoc.save(),
                filename: `Pagina_${i + 1}_${filename}-${this.getUniqueHash()}.${extension}`,
              })
            }
          } else {
            this.objDados.files.pdfs.push({ content: await pdf.save(), filename: file.name })
          }
          return
        })
      )
    },
    clear() {
      this.totalFiles = 0
      this.objDados = {
        ...this.objDados,
        files: {
          images: [],
          pdfs: [],
        },
      }
    },
    divideFilenameAndExtension(filename) {
      const split = filename.split('.')
      return {
        filename: split.slice(0, split.length - 1).join('.'),
        extension: split.pop(),
      }
    },
    getUniqueHash() {
      return Date.now()
    },
  },
  watch: {
    'objDados.files.images': {
      handler() {
        if (this.objDados.files.pdfs.length + this.objDados.files.images.length === this.totalFiles) {
          this.loading = false
        }
      },
      deep: true,
    },
    'objDados.files.pdfs': {
      handler() {
        if (this.objDados.files.pdfs.length + this.objDados.files.images.length === this.totalFiles) {
          this.loading = false
        }
      },
    },
  },
  async mounted() {
    let currentUserEmail = await UserAttributes('email')
    let currentUserName = await UserAttributes('name')
    console.log(currentUserEmail, currentUserName)
    if (
      [
        'pelissari.celso@tigre.com',
        'Amandanebel@gmail.com',
        'amandanebel@gmail.com',
        'pradocelsojr@gmail.com',
        'mouts.edilson@tigre.com',
        'Marina.felix@tgll.com.br',
        'Talita.machado@tgll.com.br',
        'marina.felix@tgll.com.br',
        'talita.machado@tgll.com.br',
        'luiz.carreta@tigre.com',
      ].find((email) => email === currentUserEmail) ||
      currentUserName.toLowerCase().includes('nebel'.toLowerCase())
    ) {
      await this.setCompanyCodeDataToList({ email: currentUserEmail })
    } else {
      this.redirect({ path: '/' })
    }
  },
}
</script>

<style scoped>
.none {
  display: none;
}
</style>
