<template>
  <div>
    <t-loading v-model="loading" />
    <t-head :header="getNamePage" :breadcrumb="getBreadcrumb" />
    <t-container>
      <v-card>
        <v-card-text>
          <v-form ref="form">
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="objDados.name"
                  :label="$t('name')"
                  outlined
                  :rules="[rules.required]"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="objDados.email"
                  :label="$t('email')"
                  outlined
                  :rules="[rules.required, rules.email]"
                  dense
                  :readonly="isUpdate"
                  :filled="isUpdate"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <SelectProfile ref="profile" v-model="objDados.profile" />
              </v-col>
            </v-row>
            <v-row v-if="isUpdate">
              <v-col cols="12" sm="12" md="8" class="companyCode__list">
                <list-company-codes-component :details="getCompanyCodesList" />
              </v-col>
            </v-row>
            <v-row class="mt-5">
              <v-col>
                <ButtonsActionForm @clickSave="saveUser" @clickCancel="cancel" />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </t-container>
  </div>
</template>

<script>
import SelectProfile from '@/components/fields/SelectProfile.vue'
import ButtonsActionForm from '@/components/ButtonsActionForm.vue'
import mixinRouter from '@/mixins/mixinRouter'
import mixinAlert from '@/mixins/mixinAlert'
import { mapActions, mapGetters, mapState } from 'vuex'
import ListCompanyCodesComponent from './components/ListCompanyCodesComponent.vue'

export default {
  components: {
    SelectProfile,
    ButtonsActionForm,
    ListCompanyCodesComponent,
  },
  mixins: [mixinRouter, mixinAlert],
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      details: [],
      objDados: {
        name: null,
        profile: null,
        email: null,
      },
      rules: {
        required: (value) => !!value || this.$i18n.t('required'),
        email: (value) => /.+@.+\..+/.test(value) || this.$i18n.t('EmailMustBeValid'),
      },
      loading: false,
    }
  },
  computed: {
    ...mapGetters('userStore', {
      getUser: 'getData',
    }),
    ...mapState({
      getCompanyCodesList(_, getters) {
        return getters['userStore/getCompanyCodeList']
      },
    }),
    getNamePage() {
      return this.isUpdate ? this.$i18n.t('updateUser') : this.$i18n.t('user')
    },
    getBreadcrumb() {
      return this.$i18n.t('user')
    },
    isUpdate() {
      return this.id !== null && this.id !== ''
    },
  },
  methods: {
    ...mapActions('groupStore', {
      listGroups: 'setDataToList',
      clearGroups: 'clearState',
    }),
    ...mapActions('userStore', {
      addUser: 'setAddData',
      updateUser: 'setUpdateData',
      setUser: 'setData',
      clearUser: 'clearState',
      listCompanyCodes: 'setCompanyCodeDataToList',
    }),
    async saveUser() {
      let valid = this.$refs.form.validate() && this.$refs.profile.validate()
      if (!valid) return
      let confirm = await this.mixinAlertConfirm()
      if (!confirm) return
      this.mixinAlertLoading()
      try {
        let data = this.getDataUser()
        if (this.isUpdate) {
          await this.updateUser(data)
        } else {
          await this.addUser(data)
          this.clear()
          this.$router.push({ name: 'user' })
        }
        await this.mixinAlertSuccess()
      } catch (error) {
        await this.mixinAlertError(error.data && error.data.error ? this.$i18n.t(error.data.error) : null)
        return
      }
    },
    getDataUser() {
      let { name, profile, email } = this.objDados
      let data = {
        name: name,
        profile: profile,
        email: email,
      }
      if (this.isUpdate) {
        data = {
          ...data,
          id: this.id,
        }
      }
      return data
    },
    cancel() {
      this.redirect({
        name: 'user',
      })
    },
    clear() {
      this.objDados = {
        name: null,
        profile: null,
        email: null,
      }
      this.$refs.form.reset()
      this.$refs.profile.reset()
      //this.clearGroups()
      this.clearUser()
    },
    setData() {
      let { name, profile, email } = this.getUser
      this.objDados = {
        name: name,
        profile: profile,
        email: email,
      }
    },
  },
  async mounted() {
    this.loading = true
    try {
      this.clear()
      if (this.isUpdate) {
        await this.setUser({ id: this.id })
        this.setData()
      }
      this.loading = false
    } catch (error) {
      this.loading = false
      this.mixinAlertError()
      return
    }
    if (this.isUpdate) {
      await this.listCompanyCodes(this.getUser)
    }
  },
}
</script>

<style>
.companyCode__list .v-sheet.theme--light {
  left: -100px !important;
}

.companyCode__list .container.container-router-view > .mt-8 {
  margin-top: 0px !important;
}
</style>
