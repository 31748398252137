<template>
  <div @click.prevent="toggleOrder()">
    <span>{{ header.text }}</span>
    <v-icon
      v-if="isSortable"
      class="orderArrow ml-2 mb-2"
      :class="pagination.multiSort ? arrowClassesMulti : arrowClasses"
      small
    >
      mdi-arrow-up
    </v-icon>
    <v-badge class="ml-2" color="#D8D8D8" v-if="paginationIndexOfValue >= 0 && pagination.multiSort">
      <span slot="badge" class="black--text">{{ paginationIndexOfValue + 1 }}</span>
    </v-badge>
  </div>
</template>

<script>
export default {
  name: 'DataTableHeaderCell',
  props: {
    header: {
      type: Object,
      required: true,
    },
    pagination: {
      type: Object,
      required: true,
    },
  },
  computed: {
    paginationIndexOfValue() {
      return this.pagination.sortBy.indexOf(this.header.value)
    },
    paginationIncludesValue() {
      return this.pagination.multiSort
        ? this.pagination.sortBy.includes(this.header.value)
        : this.pagination.sortBy == this.header.value
    },
    arrowClassesMulti() {
      return {
        inHover: this.paginationIncludesValue,
        arrowUp: !this.paginationIncludesValue ? false : !this.pagination.sortDesc[this.paginationIndexOfValue],
        arrowDown: !this.paginationIncludesValue ? false : this.pagination.sortDesc[this.paginationIndexOfValue],
      }
    },
    arrowClasses() {
      return {
        inHover: this.paginationIncludesValue,
        arrowUp: !this.paginationIncludesValue ? false : !this.pagination.sortDesc,
        arrowDown: !this.paginationIncludesValue ? false : this.pagination.sortDesc,
      }
    },
    isSortable() {
      return this.header.sortable
    },
  },
  methods: {
    toggleOrder() {
      this.pagination.multiSort
        ? this.changeSortMulti(this.header.value, this.header.sortable)
        : this.changeSort(this.header.value, this.header.sortable)
    },
    changeSortMulti(column, sortable) {
      if (!sortable) return
      if (this.paginationIncludesValue) {
        if (this.pagination.sortDesc[this.paginationIndexOfValue]) {
          this.pagination.sortBy.splice(this.paginationIndexOfValue, 1)
          this.pagination.sortDesc.splice(this.paginationIndexOfValue, 1)
        } else {
          this.pagination.sortDesc[this.paginationIndexOfValue] = true
        }
      } else {
        this.pagination.sortBy.push(column)
        this.pagination.sortDesc.push(false)
      }
      this.pagination.sortBy = [...this.pagination.sortBy]
      this.pagination.sortDesc = [...this.pagination.sortDesc]

      this.$emit('update-order', this.pagination)
    },
    changeSort(column, sortable) {
      if (!sortable) return
      if (this.paginationIncludesValue) {
        if (this.pagination.sortDesc) {
          this.pagination.sortBy = ''
          this.pagination.sortDesc = ''
        } else {
          this.pagination.sortDesc = true
        }
      } else {
        this.pagination.sortBy = column
        this.pagination.sortDesc = false
      }

      this.$emit('update-order', this.pagination)
    },
  },
}
</script>

<style>
.orderArrow {
  position: absolute;
  opacity: 0;
}

.inHover {
  opacity: 0.6;
}

.arrowUp {
  opacity: 1;
}

.arrowDown {
  opacity: 1;
  transform: rotate(180deg);
}
</style>
