<script>
import ListView from '@/components/ListView'
export default {
  extends: ListView,
  data(vm) {
    return {
      namePage: this.$i18n.t('user'),
      path: 'user',
      headerContent: () => import('@/views/user/components/UsersHeaderComponent.vue'),
      dataProperties: { path: 'user', namePage: this.$i18n.t('user') },
      headers: [
        {
          text: this.$i18n.t('name'),
          align: 'start',
          sortable: false,
          value: 'name',
          createFilter: true,
        },
        {
          text: this.$i18n.t('profile'),
          sortable: false,
          value: 'profile',
          createFilter: true,
        },
        {
          text: this.$i18n.t('email'),
          sortable: false,
          value: 'email',
          createFilter: true,
        },
        {
          text: this.$i18n.t('active'),
          sortable: false,
          value: 'active',
          component: 'DataTableTransformValueColumn',
          options: {
            transform(value) {
              return value ? vm.$i18n.t('yes') : vm.$i18n.t('no')
            },
          },
        },
        {
          text: this.$i18n.t('actions'),
          sortable: false,
          value: 'actions',
        },
      ],
    }
  },
  computed: {
    getFilter() {
      return {}
    },
  },
}
</script>
