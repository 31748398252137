import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import i18n from './translations'
import pluginTigreLayout from 'plugin-tigre-layout'
import { VueMaskDirective } from 'v-mask'
import TSweetAlertVuetify from 'plugin-tigre-sweet-alert-vuetify'
import './amplify.config'
import './assets/css/style.css'
import './assets/scss/style.scss'
import moment from './plugins/moment'
import './plugins/global-components'

Vue.directive('mask', VueMaskDirective)
Vue.use(TSweetAlertVuetify, vuetify)
Vue.use(pluginTigreLayout, vuetify)

Vue.config.productionTip = false
Vue.prototype.$moment = moment

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
