<template>
  <div>
    <v-btn :disabled="disabled" color="success" class="mr-4" @click="save"> {{ $t('save') }} </v-btn>
    <v-btn color="error" class="mr-4" @click="cancel"> {{ $t('cancel') }} </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    save() {
      this.$emit('clickSave')
    },
    cancel() {
      this.$emit('clickCancel')
    },
  },
}
</script>
