<script>
import ListView from '@/components/ListView'
import { mapActions, mapGetters } from 'vuex'
import ActionInvoicesMenuComponent from './components/ActionInvoicesMenuComponent'

export default {
  extends: ListView,
  name: 'ViewListInvoicesMobile',
  data() {
    return {
      namePage: this.$i18n.t('uploadVouchers'),
      path: 'invoices',
      filterContent: () => import('./InvoicesFiltersComponent.vue'),
      headerContent: () => import('./InvoiceHeaderComponent.vue'),
      hideDefaultActions: true,
      addNew: false,
      enablePrinting: true,
      dataProperties: { path: 'invoices', namePage: this.$i18n.t('uploadVouchers') },
      headers: [
        {
          text: this.$i18n.t('invoice'),
          align: 'start',
          sortable: true,
          value: 'documentCode',
          width: '40%',
        },
        {
          text: this.$i18n.t('transportationCode'),
          align: 'start',
          sortable: true,
          value: 'transportationCode',
          width: '40%',
        },
        {
          text: this.$i18n.t('actions'),
          value: null,
          sortable: false,
          component: ActionInvoicesMenuComponent,
          width: '20%',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('invoicesStore', {
      getGoodList: 'getList',
    }),
  },
  methods: {
    ...mapActions('invoicesStore', ['setDataToList', 'setDefaultPath']),
    async getItemsList() {
      return true
    },
  },
}
</script>
