<template>
  <v-menu :close-on-content-click="false" offset-x max-width="450" min-width="450">
    <template v-slot:activator="{ on }">
      <v-btn text v-on="on">
        <span class="mr-3 change-color-span"> CONFIGURAR COLUNAS </span>
        <v-icon color="#999999"> mdi-cog </v-icon>
      </v-btn>
    </template>
    <v-card class="pa-2" flat>
      <v-select
        v-model="select"
        :items="items"
        filled
        deletable-chips
        hide-details
        label="CONFIGURAR COLUNAS"
        multiple
        return-object
        small-chips
        append-icon="mdi-plus"
      />
    </v-card>
  </v-menu>
</template>

<script>
import DataTableService from '@/components/data-table/DatatableService'

export default {
  name: 'DataTableConfig',
  props: {
    value: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    tableHeadersKey: {
      type: String,
      default: '',
    },
  },
  computed: {
    select: {
      get() {
        return this.value
      },
      set(value) {
        DataTableService.saveHeaders(this.tableHeadersKey, value)
        this.$emit('input', value)
      },
    },
  },
}
</script>

<style lang="sass" scoped>
.change-color-span
  color: #999999
</style>
