import Vue from 'vue'
import VueI18n from 'vue-i18n'
import pt from './languages/pt'

Vue.use(VueI18n)

let language = window.navigator.language || window.navigator.userLanguage
language = language.substring(0, 2)
if (['pt', 'en'].indexOf(language) == -1) {
  language = 'pt'
}

export default new VueI18n({
  locale: 'pt',
  messages: {
    pt,
  },
})
