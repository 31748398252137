const name = 'occurrences'
const path = '/occurrences'

export default [
  {
    path: `${path}`,
    name: `${name}`,
    component: () => import('./ViewListOccurrences.vue'),
    meta: {
      requiresAuth: true,
    },
  },
]
