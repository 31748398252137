export const DATA_TO_LIST = 'setDataToList'
export const DATA_TO_CHANGE = 'setData'
export const CLEAR_STATE = 'clearState'
export const LOADING = 'setLoading'
export const PAGINATION = 'setPagination'
export const FILTER = 'setFilter'
export const MESSAGE = 'setMessage'

export default {
    DATA_TO_LIST,
    DATA_TO_CHANGE,
    CLEAR_STATE,
    MESSAGE,
    LOADING,
    PAGINATION,
    FILTER,
}