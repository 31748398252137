import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify)

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#00418A',
        secondary: '#EE7900',
        input: '#757575',
        background: '#FCFCFC',
        text: '#757575',
        'text-active': '#fff',
      },
    },
    options: {
      customProperties: true,
    },
  },
  breakpoint: {
    mobileBreakpoint: 'sm',
  },
  icons: {
    values: {
      search: 'mdi-magnify',
      notification: 'mdi-bell',
    },
  },
})

export default vuetify
