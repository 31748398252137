<template>
  <chip-component :payload="getStatus" />
</template>

<script>
import ChipComponent from '@/components/ChipComponent.vue'

export default {
  name: 'YesNoChipStatus',
  components: { ChipComponent },
  props: {
    value: {
      type: [String, Boolean],
      default: null,
      required: false,
    },
    options: {
      type: Object,
      default() {
        return {
          // eslint-disable-next-line no-unused-vars
          transform: (value, row) => value,
        }
      },
    },
  },
  data: (vm) => ({
    availableStatus: {
      true: {
        color: 'green',
        textColor: 'white',
        label: vm.$i18n.t('yes'),
      },
      false: {
        color: 'red',
        textColor: 'white',
        label: vm.$i18n.t('no'),
      },
    },
  }),
  computed: {
    getStatus() {
      return this.availableStatus[this.options.transform(this.value)]
    },
  },
}
</script>
