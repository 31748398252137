import { DATA_TO_LIST, DATA_TO_CHANGE, CLEAR_STATE, LOADING, PAGINATION, FILTER } from './types'

import Request from 'plugin-tigre-request'
import utils from '@/utils'

export default (resource, requestDataFn) => {
  const defaultRequestParam = () => {
    let request = requestDataFn() || utils.makeDefaultRequestData()
    return {
      ...request,
      resource: `/${resource}`,
    }
  }

  const PromiseReturn = (method, path, data) => {
    let requestParam = {
      ...defaultRequestParam(),
      data,
      method,
      path,
    }
    return new Promise((resolve, reject) => {
      Request(requestParam)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  return {
    store: {
      namespaced: true,
      state: {
        list: [],
        data: {},
        loading: false,
        pagination: {
          page: 1,
          limit: 10,
          count: 0,
        },
        filters: {},
      },
      getters: {
        getList(state) {
          return state.list
        },
        getData(state) {
          return state.data
        },
        getLoading(state) {
          return state.loading
        },
        getFilters(state) {
          return state.filters
        },
        getPagination(state) {
          return {
            limit: state.pagination.limit,
            page: state.pagination.page,
          }
        },
        getCount(state) {
          return state.pagination.count
        },
        getListFilter(state) {
          let list = state.list
          const keys = Object.keys(state.filters)

          keys.forEach((key) => {
            list = list.filter((item) => (state.filters[key] != null ? item[key]?.includes(state.filters[key]) : item))
          })
          return list
        },
      },
      mutations: {
        setDataToList(state, payload) {
          const { data, count } = payload
          state.list = data
          state.pagination.count = count
        },
        setData(state, payload) {
          const { data } = payload
          state.data = data
        },
        clearState(state) {
          state.list = []
          state.data = {}
          state.loading = false
          state.pagination = {
            page: 1,
            limit: 10,
            count: 0,
          }
          state.filters = {}
        },
        setLoading(state, payload) {
          state.loading = payload
        },
        setPagination(state, payload) {
          state.pagination.limit = payload.limit
          state.pagination.page = payload.page
        },
        setFilter(state, payload) {
          state.filters = {
            ...payload,
          }
          state.pagination.page = 1
        },
      },
      actions: {
        setPagination({ commit }, data) {
          commit(PAGINATION, data)
        },
        setFilter({ commit }, data) {
          commit(FILTER, data)
        },
        setAddData(_, data) {
          return PromiseReturn('POST', '', data)
        },
        setUpdateData(_, data) {
          let id = data.id
          delete data.id
          return PromiseReturn('PUT', `/${id}`, data)
        },
        setDeleteData(_, data) {
          return PromiseReturn('DELETE', `/${data.id}`)
        },
        setData({ commit }, data) {
          let requestParam = {
            ...defaultRequestParam(),
            method: 'GET',
            path: `/${data.id}`,
          }
          return new Promise((resolve, reject) => {
            Request(requestParam)
              .then((response) => {
                commit(DATA_TO_CHANGE, {
                  data: response.data.data,
                  request: requestParam,
                })
                resolve(response)
              })
              .catch((error) => {
                reject(error)
              })
          })
        },
        setDataToList({ commit }, data) {
          let requestParam = {
            ...defaultRequestParam(),
            data: data,
            method: 'GET',
          }
          commit(LOADING, true)
          return new Promise((resolve, reject) => {
            Request(requestParam)
              .then((response) => {
                commit(DATA_TO_LIST, {
                  data: response.data.data,
                  count: response.data.count,
                  request: requestParam,
                })
                commit(LOADING, false)
                resolve(response)
              })
              .catch((error) => {
                reject(error)
              })
          })
        },
        clearState({ commit }) {
          commit(CLEAR_STATE)
        },
      },
    },
    defaultRequestParam,
  }
}
