<template>
  <v-menu
    ref="menuDate"
    v-model="menuDate"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model.lazy="computedDateFormatted"
        v-bind="attrs"
        v-on="on"
        :label="label"
        :outlined="outlined"
        :rules="getRules"
        :placeholder="placeholder"
        :clearable="clearable"
        :prepend-icon="prependIcon"
        :append-icon-cb="(menu = true)"
        :disabled="disabled"
        :readonly="readonly"
        :dense="dense"
        v-mask="'##/##/####'"
        @click:clear="clear"
      >
        <v-tooltip top slot="append" v-if="infoText">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on"> mdi-information-outline </v-icon>
          </template>
          <span> {{ infoText }}</span>
        </v-tooltip>
      </v-text-field>
    </template>
    <v-date-picker
      v-model="getValue"
      scrollable
      @input="menuDate = false"
      locale="pt-BR"
      :allowed-dates="allowedDatesMethod"
    />
  </v-menu>
</template>

<script>
import mixinInputDate from '@/mixins/mixinInputDate'

export default {
  name: 'InputDate',
  mixins: [mixinInputDate],
}
</script>
