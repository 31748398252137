export default {
  data() {
    return {
      password: '',
      confirmNewPassword: '',
      rules: {
        required: (value) => !!value || this.$i18n.t('required'),
        email: (value) => /.+@.+\..+/.test(value) || this.$i18n.t('EmailMustBeValid'),
        equalPasswords: () => this.equalPasswords || this.$i18n.t('passwordNotMatch'),
        lengthPassword: (value) => !value || value.length >= 6 || this.$i18n.t('minimumLength', { number: 6 }),
      },
    }
  },
  computed: {
    equalPasswords() {
      return this.password === this.confirmNewPassword
    },
  },
}
