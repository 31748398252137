const name = 'companiesConfig'
const path = '/companies-config'

export default [
  {
    path: `${path}`,
    name: `${name}`,
    component: () => import('./ViewListCompanies.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: `${path}/add`,
    name: `${name}Add`,
    component: () => import('./ViewFormCompanies.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: `${path}/:id`,
    name: `${name}Update`,
    component: () => import('./ViewFormCompanies.vue'),
    meta: { requiresAuth: true },
    props: true,
  },
]
