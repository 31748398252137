<template>
  <div :class="computedClass">
    {{ value || ' - ' }}
  </div>
</template>

<script>
export default {
  name: 'DataTableTextColumn',
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    options: {
      type: Object,
      default() {
        return {
          shouldWrap: false,
        }
      },
    },
  },
  computed: {
    computedClass() {
      return {
        noWrap: !this.options.shouldWrap,
      }
    },
  },
}
</script>
