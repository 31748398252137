<template>
  <div>
    <t-loading v-model="loading" />
    <v-row class="signin">
      <v-col class="card-signin">
        <v-img :src="logo" max-width="150" contain class="center-img" />
        <h2 class="white--text text-uppercase">{{ $t('forgotPassword') }}</h2>
        <v-alert v-if="showAlert" :type="typeAlert" dense>{{ messageAlert }}</v-alert>
        <v-form
          ref="form"
          @submit.prevent="resetPassword"
          v-if="!confirmationCodeAndPassword && !successUpdateNewPassword"
        >
          <v-text-field
            v-model="email"
            :label="$t('email')"
            outlined
            dark
            :rules="[rules.required, rules.email]"
            filled
            dense
          ></v-text-field>
          <v-btn type="submit" color="secondary white--text" dark width="100%">
            {{ $t('resetPassword') }}
          </v-btn>
        </v-form>
        <v-form
          ref="formConfirmCode"
          @submit.prevent="submitCodeAndNewPassowrd"
          v-if="confirmationCodeAndPassword && !successUpdateNewPassword"
        >
          <v-text-field
            v-model="code"
            :label="$t('code')"
            outlined
            dark
            :rules="[rules.required]"
            filled
            dense
          ></v-text-field>
          <v-text-field
            v-model="password"
            :label="$t('newPassword')"
            outlined
            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPass = !showPass"
            :type="showPass ? 'text' : 'password'"
            dark
            :rules="[rules.required, rules.lengthPassword]"
            filled
            dense
          ></v-text-field>
          <v-text-field
            v-model="confirmNewPassword"
            :label="$t('confirmNewPassword')"
            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPass = !showPass"
            outlined
            :rules="[rules.equalPasswords, rules.lengthPassword]"
            dense
            dark
            filled
            :type="showPass ? 'text' : 'password'"
          ></v-text-field>
          <v-btn type="submit" color="secondary white--text" dark width="100%">
            {{ $t('confirm') }}
          </v-btn>
        </v-form>
        <v-btn @click="redirectLogin" color="secondary white--text" dark width="100%" v-if="successUpdateNewPassword">
          {{ $t('back') }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import logo from '../assets/img/logo.png'
import { Auth } from 'aws-amplify'
import mixinRouter from '@/mixins/mixinRouter'
import mixinErrorCognito from '@/mixins/mixinErrorCognito'
import mixinLoginRules from '@/mixins/mixinLoginRules'

export default {
  mixins: [mixinRouter, mixinErrorCognito, mixinLoginRules],
  data() {
    return {
      messageAlert: '',
      typeAlert: '',
      email: '',
      code: null,
      confirmationCodeAndPassword: false,
      loading: false,
      logo: logo,
      showPass: false,
      successUpdateNewPassword: false,
    }
  },
  computed: {
    equalPasswords() {
      return this.password === this.confirmNewPassword
    },
    showAlert() {
      return this.typeAlert !== ''
    },
  },
  methods: {
    async resetPassword() {
      this.clear()
      const valid = await this.$refs.form.validate()
      if (!valid) return
      this.loading = true
      try {
        await Auth.forgotPassword(this.email)
        this.setSuccess(this.$i18n.t('emailSent'))
        this.confirmationCodeAndPassword = true
        this.$nextTick(() => {
          this.$refs.formConfirmCode.reset()
        })
      } catch (error) {
        console.error(error)
        this.setError(error?.code)
      }
      this.loading = false
    },
    async submitCodeAndNewPassowrd() {
      const valid = await this.$refs.formConfirmCode.validate()
      if (!valid) return
      this.loading = true
      try {
        await Auth.forgotPasswordSubmit(this.email, this.code, this.confirmNewPassword)
        this.successUpdateNewPassword = true
        this.setSuccess(this.$i18n.t('successUpdateNewPassword'))
      } catch (error) {
        console.error(error)
        this.setError(error?.code)
      }
      this.loading = false
    },
    setSuccess(message) {
      this.typeAlert = 'success'
      this.messageAlert = message
    },
    setError(code) {
      this.typeAlert = 'error'
      this.messageAlert = this.getMessageError(code)
    },
    clear() {
      this.confirmationCodeAndPassword = false
      this.typeAlert = ''
      this.messageAlert = ''
    },
    redirectLogin() {
      this.redirect({
        path: '/login',
      })
    },
  },
}
</script>
