<template>
  <button @click="exportExcel">
    <slot></slot>
  </button>
</template>

<script>
import XLSX from 'xlsx/xlsx'
import mixinAlert from '@/mixins/mixinAlert'

export default {
  name: 'GenetearXLSXButton',
  mixins: [mixinAlert],
  data() {
    return {
      wb: null,
      name: '',
    }
  },
  props: {
    columns: {
      type: Array,
      default() {
        return []
      },
    },
    data: {
      type: Array,
      default() {
        return []
      },
    },
    filename: {
      type: String,
      default: 'excel',
    },
    sheetname: {
      type: String,
      default: 'SheetName',
    },
  },
  methods: {
    async downloadFile() {
      const confirmation = await this.mixinAlertConfirm(this.$t('confirmDownloadExcelFile'))
      if (!confirmation) {
        return
      }
      XLSX.writeFile(this.wb, this.name)
    },
    exportExcel() {
      let createXLSLFormatObj = []
      let newXlsHeader = []
      let vm = this
      if (vm.columns.length === 0) {
        throw Error('Columns not informed')
      }
      if (vm.data.length === 0) {
        throw Error('Data empty')
      }
      vm.columns.forEach((value) => {
        newXlsHeader.push(value.label)
      })
      createXLSLFormatObj.push(newXlsHeader)
      vm.data.forEach((value) => {
        let innerRowData = []
        vm.columns.forEach((val) => {
          if (val.dataFormat && typeof val.dataFormat === 'function') {
            innerRowData.push(val.dataFormat(value[val.field]))
          } else {
            innerRowData.push(value[val.field])
          }
        })
        createXLSLFormatObj.push(innerRowData)
      })
      let filename = vm.filename + '.xlsx'
      let ws_name = vm.sheetname
      let wb = XLSX.utils.book_new(),
        ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj)
      XLSX.utils.book_append_sheet(wb, ws, ws_name)

      this.name = filename
      this.wb = wb

      this.downloadFile()
    },
  },
}
</script>
