<template>
  <v-form ref="form">
    <v-autocomplete
      :value="modelProfile"
      :items="getProfiles"
      :rules="[rules.required]"
      :label="$t('profile')"
      item-text="GroupName"
      item-value="GroupName"
      outlined
      dense
      @input="input"
    ></v-autocomplete>
  </v-form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  model: {
    prop: 'modelProfile',
  },
  props: {
    modelProfile: {
      type: String || Object,
      default: () => {
        return {}
      },
    },
  },
  async mounted() {
    try {
      this.clearGroups()
      await this.listGroups()
    } catch (error) {
      return
    }
  },
  computed: {
    ...mapGetters('groupStore', {
      getProfiles: 'getList',
    }),
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || this.$i18n.t('required'),
      },
    }
  },
  methods: {
    validate() {
      return this.$refs.form.validate()
    },
    reset() {
      return this.$refs.form.reset()
    },
    input($event) {
      this.$emit('input', $event)
    },
    ...mapActions('groupStore', {
      listGroups: 'setDataToList',
      clearGroups: 'clearState',
    }),
  },
}
</script>
