import Request from 'plugin-tigre-request'
import utils from '@/utils'
import crypto from 'crypto'
import UserAttributes from '@/helpers/UserAttributes'

const basePath = '/transport-co/users/electronic-bill-of-lading'

/*const defaultRequestParam = (path) => {
  return {
    ...utils.makeDefaultRequestData(),
    resource: `${path}`,
  }
}*/

const types = {
  DATA_TO_LIST: 'setDataToList',
  FILTER: 'setFilter',
  CLEAR_STATE: 'clearState',
}
export default {
  namespaced: true,
  state: {
    list: [],
    filters: {},
    message: '',
  },
  getters: {
    getList(state) {
      return state.list
    },
    getMessage(state) {
      return state.message
    },
    getListFilter(state) {
      let list = state.list
      const keys = Object.keys(state.filters)

      keys.forEach((key) => {
        if (key == 'code') {
          list = list.filter((item) =>
            state.filters[key] != null && state.filters[key].length > 0 ? state.filters[key].includes(item[key]) : item
          )
        } else {
          list = list.filter((item) => (state.filters[key] != null ? item[key]?.includes(state.filters[key]) : item))
        }
      })

      return list
    },
    getFilter(state) {
      return state.filters
    },
  },
  mutations: {
    setDataToList(state, payload) {
      const { data, message } = payload
      state.list = data
      state.message = message || ''
    },
    clearState(state, clearFilter) {
      state.list = []
      if (clearFilter) {
        state.filters = {}
      }
      state.message = ''
    },
    setFilter(state, payload) {
      state.filters = payload
    },
  },
  actions: {
    setFilter({ commit }, data) {
      commit(types.FILTER, data)
    },
    async setDataToList({ commit }, data) {
      commit(types.FILTER, {})
      let queryParams = data
      let companyCode = data.companyCode
      const hashCache = crypto.createHash('md5').update(JSON.stringify(queryParams)).digest('hex')
      delete data.companyCode

      queryParams['cache-control'] = hashCache

      let requestParam = {
        ...utils.makeDefaultRequestMuleData(),
        data: queryParams,
        method: 'GET',
      }
      requestParam.headers['companyCode'] = companyCode
      requestParam.headers['e-mail'] = await UserAttributes('email')
      requestParam.headers['path'] = `/api${basePath}`

      let response
      try {
        response = await Request(requestParam)
        commit(types.DATA_TO_LIST, {
          data: response.data.electronicBillLading || [],
          message: response.data.message || '',
          request: requestParam,
        })
      } catch (error) {
        if (error.response) {
          response = error.response.data[0] || error.response.data
        } else {
          response = error
        }
      }
      return response
    },
    clearState({ commit }, data) {
      commit(types.CLEAR_STATE, data)
    },
  },
}
