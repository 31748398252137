<template>
  <v-app>
    <TMenu
      v-model="menuSelect"
      :menu-items="menuItems"
      :src="logo"
      :srcProfile="srcProfile"
      :nameProfile="user.name"
      :menuItemsProfile="menuItemsProfile"
      logo-max-width="100"
      logo-max-height="100"
      no-notification
      no-search
      @change="redirectMenu"
      @logout="logout"
      v-if="isAuth"
    />
    <v-main class="background" v-if="isAuth">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </v-main>
    <v-main class="primary overflow-hidden" v-if="!isAuth">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </v-main>
  </v-app>
</template>
<script>
import logo from './assets/img/logo.png'
import profileAvatar from './assets/img/profile.png'
import mixinRouter from '@/mixins/mixinRouter'
import { Auth, Hub } from 'aws-amplify'
export default {
  mixins: [mixinRouter],
  name: 'App',
  data() {
    return {
      menuSelect: '/',
      srcProfile: profileAvatar,
      logo: logo,
      isAuth: true,
      user: { name: '' },
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
    menuItemsProfile() {
      return [
        {
          text: this.$i18n.t('exit'),
          value: '/logout',
          emitEventClick: 'logout',
        },
      ]
    },
    menuItems() {
      const profile = this.user?.profile || 'user'
      if (profile === 'admin') {
        return [...this.menuItemsAdmin, ...this.menuItemsUser]
      }
      return this.menuItemsUser
    },
    menuItemsAdmin() {
      return [
        {
          text: this.$i18n.t('user'),
          value: '/user',
          icon: 'mdi-account',
        },
        {
          text: this.$i18n.t('companiesConfig'),
          value: '/companies-config',
          icon: 'mdi-domain',
        },
        // {
        //   text: this.$i18n.t('massDelivery'),
        //   value: '/mass-delivery',
        //   icon: 'mdi-note-multiple-outline',
        // },
      ]
    },
    menuItemsUser() {
      let menu = []
      menu.push(this.buildMenuItem('transportationDocuments', '/transportation-documents', 'mdi-tag'))
      menu.push(this.buildMenuItem('invoicesOccurrences', '/invoices', 'mdi-calendar-outline'))
      if (this.isMobile) {
        menu.push(this.buildMenuItem('uploadVouchers', '/mobile-invoices', 'mdi-calendar-plus'))
      }
      menu.push(this.buildMenuItem('monitor', '/occurrences', 'mdi-compass-outline'))
      console.log(this.user)
      if (
        [
          'pelissari.celso@tigre.com',
          'Amandanebel@gmail.com',
          'amandanebel@gmail.com',
          'pradocelsojr@gmail.com',
          'mouts.edilson@tigre.com',
          'Marina.felix@tgll.com.br',
          'Talita.machado@tgll.com.br',
          'marina.felix@tgll.com.br',
          'talita.machado@tgll.com.br',
          'luiz.carreta@tigre.com',
        ].find((email) => email === this.user.email) ||
        this.user.name.toLowerCase().includes('nebel'.toLowerCase())
      ) {
        menu.push(this.buildMenuItem('massDelivery', '/mass-delivery', 'mdi-note-multiple-outline'))
      }
      return menu
    },
  },
  methods: {
    buildMenuItem(label, path, icon) {
      return {
        text: this.$i18n.t(label),
        value: path,
        icon: icon,
      }
    },
    async logout() {
      await Auth.signOut()
      this.redirect({
        name: 'login',
      })
    },
    redirectMenu(path) {
      this.redirect(path)
    },
    async validAuth() {
      try {
        let { attributes } = await Auth.currentAuthenticatedUser()
        this.user = attributes
        this.isAuth = true
      } catch (error) {
        this.isAuth = false
        this.user = null
      }
    },
    async selectMenu() {
      this.menuSelect = this.$router.currentRoute.path
    },
  },
  created() {
    this.validAuth()

    setInterval(() => {
      Hub.dispatch('auth', {
        event: 'forceRefresh',
      })
    }, 240000)
  },
  mounted() {
    this.selectMenu()
  },
  watch: {
    '$route.currentRoute': {
      handler: function () {
        this.selectMenu()
        this.validAuth()
      },
      deep: true,
    },
  },
}
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.2s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
