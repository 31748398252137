<template>
  <div class="noWrap">
    {{ data }}
  </div>
</template>

<script>
import Moment from 'moment'

export default {
  name: 'DataTableDateColumn',
  props: {
    value: {
      type: [Date, String],
      default: '',
    },
  },
  computed: {
    data() {
      if (!this.value) return ''
      if (typeof this.value === 'string' && this.value !== '') {
        return Moment(this.value).format('DD/MM/YYYY HH:mm')
      }
      return this.value
    },
  },
}
</script>
