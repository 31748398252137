<template>
  <div>
    <t-loading v-model="loading" />
    <v-row class="signin">
      <v-col class="card-signin">
        <v-img :src="logo" max-width="150" contain class="center-img" />
        <h2 class="white--text text-uppercase">{{ getTitle }}</h2>
        <v-alert v-if="showAlert" :type="typeAlert" dense>{{ messageAlert }}</v-alert>
        <v-form ref="form" @submit.prevent="login" v-if="!newPasswordRequired">
          <v-text-field
            v-model="email"
            :label="$t('email')"
            outlined
            dark
            :rules="[rules.required, rules.email]"
            filled
            dense
          ></v-text-field>
          <v-text-field
            v-model="password"
            :label="$t('password')"
            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPass = !showPass"
            outlined
            :rules="[rules.required]"
            dense
            dark
            filled
            :type="showPass ? 'text' : 'password'"
          ></v-text-field>
          <v-row class="mb-0 text-right">
            <v-col class="py-1">
              <a href="" @click.prevent="forgotPassword" class="white--text pb-2">{{ $t('forgotPassword') }}</a>
            </v-col>
          </v-row>
          <v-btn type="submit" color="secondary white--text" dark width="100%">
            {{ $t('signIn') }}
          </v-btn>
        </v-form>
        <v-form ref="formNewPassword" @submit.prevent="loginNewPassword" v-if="newPasswordRequired">
          <v-text-field
            v-model="password"
            :label="$t('newPassword')"
            outlined
            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPass = !showPass"
            :type="showPass ? 'text' : 'password'"
            dark
            :rules="[rules.required, rules.lengthPassword]"
            filled
            dense
          ></v-text-field>
          <v-text-field
            v-model="confirmNewPassword"
            :label="$t('confirmNewPassword')"
            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPass = !showPass"
            outlined
            :rules="[rules.equalPasswords, rules.lengthPassword]"
            dense
            dark
            filled
            :type="showPass ? 'text' : 'password'"
          ></v-text-field>
          <v-btn type="submit" color="secondary white--text" dark width="100%">
            {{ $t('signIn') }}
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import logo from '../assets/img/logo.png'
import { Auth } from 'aws-amplify'
import mixinRouter from '@/mixins/mixinRouter'
import mixinErrorCognito from '@/mixins/mixinErrorCognito'
import mixinLoginRules from '@/mixins/mixinLoginRules'

export default {
  mixins: [mixinRouter, mixinErrorCognito, mixinLoginRules],
  data() {
    return {
      user: null,
      messageAlert: '',
      typeAlert: '',
      email: '',
      newPasswordRequired: '',
      showPass: false,
      loading: false,
      logo: logo,      
    }
  },
  computed: {
    getTitle() {
      return this.newPasswordRequired ? this.$i18n.t('newPassword') : this.$i18n.t('login')
    },
    showAlert() {
      return this.typeAlert !== ''
    },
  },
  methods: {
    async loginNewPassword() {
      this.clearAlert()
      const valid = await this.$refs.formNewPassword.validate()
      if (!valid) return
      this.loading = true
      try {
        const { challengeParam } = this.user
        const { userAttributes } = challengeParam
        await Auth.completeNewPassword(this.user, this.confirmNewPassword, {
          name: userAttributes.name || userAttributes.email,
          profile: userAttributes.profile || 'admin',
        })
        this.redirectHome()
      } catch (error) {
        console.error(error)
        this.setError(error?.code)
      }
      this.loading = false
    },
    async login() {
      this.clearAlert()
      const valid = await this.$refs.form.validate()
      if (!valid) return
      this.loading = true
      try {
        this.user = await Auth.signIn(this.email, this.password)
        this.newPasswordRequired = this.user?.challengeName === 'NEW_PASSWORD_REQUIRED'
        if (this.newPasswordRequired) {
          this.resetFormNewPassword()
          this.loading = false
          return
        }
        this.redirectHome()
      } catch (error) {
        console.error(error)
        this.setError(error?.code)
      }
      this.loading = false
    },
    setError(code) {
      this.typeAlert = 'error'
      this.messageAlert = this.getMessageError(code)
    },
    resetFormNewPassword() {
      this.password = ''
      this.$nextTick(function () {
        this.$refs.formNewPassword.reset()
      })
    },
    clearAlert() {
      this.typeAlert = ''
      this.messageAlert = ''
    },
    clear() {
      this.email = ''
      this.user = null
      this.password = ''
      this.confirmNewPassword = ''
      this.newPasswordRequired = false
    },
    redirectHome() {
      this.redirect({
        path: '/',
      })
    },
    forgotPassword() {
      this.redirect({
        path: '/forgot-password',
      })
    },
  },
}
</script>
