import YesNoChipStatus from '@/components/YesNoChipStatus.vue'
import DataTableTextColumn from '@/components/DataTableTextColumn'
import ProgressComponentColumn from '@/components/ProgressComponentColumn'
import DataTableTransformValueColumn from '@/components/DataTableTransformValueColumn'
import DataTableCurrencyColumn from '@/components/DataTableCurrencyColumn'
import DataTableDateOnlyColumn from '@/components/DataTableDateOnlyColumn'
import DataTableDateColumn from '@/components/DataTableDateColumn'

import Vue from 'vue'

const GlobalComponents = {
  install: (VueInstance) => {
    VueInstance.component('YesNoChipStatus', YesNoChipStatus)
    VueInstance.component('DataTableTextColumn', DataTableTextColumn)
    VueInstance.component('ProgressComponentColumn', ProgressComponentColumn)
    VueInstance.component('DataTableTransformValueColumn', DataTableTransformValueColumn)
    VueInstance.component('DataTableCurrencyColumn', DataTableCurrencyColumn)
    VueInstance.component('DataTableDateOnlyColumn', DataTableDateOnlyColumn)
    VueInstance.component('DataTableDateColumn', DataTableDateColumn)
  },
}

Vue.use(GlobalComponents)
