<template>
  <div class="chip-status">
    <v-chip :color="payload.color" :text-color="payload.textColor" class="chip__width" small>
      {{ payload.label }}
    </v-chip>
  </div>
</template>

<script>
export default {
  name: 'ChipComponent',
  props: {
    payload: {
      type: Object,
      required: true,
      default() {
        return {
          color: '',
          textColor: '',
          label: '',
        }
      },
    },
  },
}
</script>
<style scoped>
.chip__width {
  min-width: 60px;
  justify-content: center;
}
</style>
