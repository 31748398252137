<template>
  <v-progress-linear :color="getStatus.color" height="20" :value="value">
    <template v-slot:default="{ value }">
      <strong :color="getStatus.textColor">{{ Math.ceil(value) }}%</strong>
    </template>
  </v-progress-linear>
</template>

<script>
export default {
  name: 'YesNoChipStatus',
  props: {
    value: {
      type: [String, Number],
      default: '',
      required: true,
    },
  },
  data: () => ({
    availableStatus: {
      DANGER: {
        color: 'red',
        textColor: 'white',
      },
      WARNING: {
        color: 'amber',
        textColor: 'white',
      },
      SUCCESS: {
        color: 'green',
        textColor: 'white',
      },
    },
  }),
  computed: {
    getStatus() {
      let status = ''
      if (this.value < 50) {
        status = 'DANGER'
      } else if (this.value >= 50 && this.value < 100) {
        status = 'WARNING'
      } else {
        status = 'SUCCESS'
      }

      return this.availableStatus[status]
    },
  },
}
</script>
