<template>
  <div class="noWrap">
    {{ currencyConvert }}
  </div>
</template>

<script>
export default {
  name: 'DataTableCurrencyColumn',
  props: {
    value: {
      type: [Number, String],
      default: 0,
    },
    options: {
      type: Object,
      default() {
        return {
          prefix: 'R$ ',
          dynamicPrefix: undefined,
        }
      },
    },
    row: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  computed: {
    prefix() {
      if (this.options.dynamicPrefix) {
        return this.getPropByString(this.row, this.options.dynamicPrefix)
      }
      return this.options.prefix
    },
    currencyConvert() {
      const currencyToNumber = parseFloat(this.value)
      const currency = currencyToNumber.toFixed(2).split('.')
      currency[0] = this.prefix.concat(' ').concat(currency[0].split(/(?=(?:...)*$)/).join('.'))
      return currency.join(',')
    },
  },
  methods: {
    getPropByString(obj, desc) {
      return desc.split('.').reduce((a, b) => a[b] || {}, obj)
    },
  },
}
</script>
