const name = 'invoices'
const path = '/invoices'

export default [
  {
    path: `${path}`,
    name: `${name}`,
    component: () => import('./ViewListInvoices.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: `${path}/add`,
    name: `${name}Add`,
    component: () => import('./ViewListInvoices.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: `${path}/:id`,
    name: `${name}Update`,
    component: () => import('./ViewListInvoices.vue'),
    meta: { requiresAuth: true },
    props: true,
  },
]
