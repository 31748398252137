<template>
  <div>
    <v-btn color="primary" class="white--text" block @click="openSelectWindow()" icon>
      <v-icon>mdi-camera</v-icon>
    </v-btn>
    <input
      id="file"
      ref="file"
      accept=".png, .jpg, .jpeg, .bmp"
      style="display: none"
      type="file"
      capture="user"
      @change="onFilePicked($event)"
    />
    <TLoading v-model="loading" />
  </div>
</template>

<script>
import axios from 'axios'
import utils from '@/utils'
import config from '@/config'
import mixinAlert from '@/mixins/mixinAlert'

export default {
  mixins: [mixinAlert],
  components: {},
  props: {
    row: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      file: null,
      loading: false,
    }
  },
  methods: {
    openSelectWindow() {
      this.$refs.file.click()
    },
    convertToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
      })
    },
    async onFilePicked(e) {
      const { files } = e.target
      if (!files[0]) return
      const confirmation = await this.mixinAlertConfirm(this.$t('confirmFileUpload'))
      if (!confirmation) {
        this.$refs.file.value = ''
        return
      }

      this.loading = true

      let payload = {}
      await this.convertToBase64(files[0]).then((data) => {
        payload.base64String = data
        payload.nfe = this.row.documentCode
        payload.cte = this.row.transportationCode
        payload.cnpj = this.row.transportCompanyCode
        payload.fileName = files[0].name
      })

      axios
        .post(`${config.BACKEND_URL}/file`, payload, {
          ...utils.makeBackendRequestData(),
        })
        .then(() => {
          this.mixinAlertSuccess(this.$t('fileUploadStubConcluded'))
        })
        .catch((error) => {
          this.mixinAlertError(error)
        })
        .then(() => {
          this.$refs.file.value = ''
          this.loading = false
        })
    },
  },
}
</script>
