module.exports = {
  TOKEN_STORAGE: 'CognitoIdentityServiceProvider.e-transportador',
  ID_TOKEN_STORAGE: 'IdTokenCognitoIdentityServiceProvider.e-transportador',
  AWS_ID_IDENTITY_POOL: process.env.VUE_APP_AWS_ID_IDENTITY_POOL || 'us-east-1:1590139a-458b-4a05-9e45-bd9d0018a49b',
  AWS_ID_GROUP_USER_POOL: process.env.VUE_APP_AWS_ID_GROUP_USER_POOL || 'us-east-1_N0tNYEfEF',
  AWS_USER_POOL_ID_CLIENT: process.env.VUE_APP_AWS_USER_POOL_ID_CLIENT || '8h4q047308t33ieor273ib9i6',
  AWS_REGION: process.env.VUE_APP_AWS_REGION || 'us-east-1',
  AWS_TEXTRACT_BUCKET: process.env.VUE_APP_AWS_TEXTRACT_BUCKET || 'e-transportador-textract-erq',
  AWS_S3_TEXTRACT_BUCKET_KEY: process.env.VUE_APP_AWS_S3_TEXTRACT_BUCKET_KEY || 'AKIAYL32DI4EBNNKM5NE',
  AWS_S3_TEXTRACT_BUCKET_SECRET_KEY:
    process.env.VUE_APP_AWS_S3_TEXTRACT_BUCKET_SECRET_KEY || '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',
  AWS_TEXTRACT_SERVICE:
    process.env.VUE_APP_AWS_TEXTRACT_SERVICE || 'https://zh3uegx6j44esfjzcc7fo2cayi0orszo.lambda-url.us-east-1.on.aws/',
  USER_MANAGER_BASE_URL:
    process.env.VUE_APP_USER_MANAGER_BASE_URL || 'https://jpzpe8q2p5.execute-api.us-east-1.amazonaws.com/development',
  API_GATEWAY_URL:
    process.env.VUE_APP_API_GATEWAY_URL || 'https://v24vw4b79f.execute-api.us-east-1.amazonaws.com/dev/e-transportador',
  BACKEND_URL:
    process.env.VUE_APP_BACKEND_URL || 'https://cgfdbzxl354tncyiwonchtc6jy0uokrr.lambda-url.us-east-1.on.aws/',
  DANFE_SERVICE_URL:
    process.env.VUE_APP_DANFE_SERVICE_URL ||
    'https://gjrsieq6b1.execute-api.us-east-1.amazonaws.com/erq/nfe/pdf-nota-fiscal',
  MULE_INVOICE_LAMBDA_URL:
    process.env.MULE_INVOICE_LAMBDA_URL || 'https://uqmeklsqdcy6pzfo6pjayj5wny0ptwlc.lambda-url.us-east-1.on.aws/',
}
