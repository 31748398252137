<template>
  <div class="noWrap">
    {{ data }}
  </div>
</template>

<script>
import Moment from 'moment'

export default {
  name: 'DataTableDateOnlyColumn',
  props: {
    value: {
      type: [Date, String],
      default: '',
    },
  },
  computed: {
    data() {
      if (!this.value) return ''
      if (typeof this.value === 'string' && this.value !== '' && this.value.length == 8) {
        return Moment(this.value, 'DD/MM/YY').format('DD/MM/YYYY')
      }
      return this.value
    },
  },
}
</script>
