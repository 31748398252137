import { Auth } from 'aws-amplify'

const UserAttributes = async (attribute) => {
  var data = ''
  try {
    const session = await Auth.currentSession()
    const idToken = session.getIdToken()
    const payload = idToken.decodePayload()
    data = payload[attribute]
  } catch (error) {
    data = error
  }
  return data
}

export default UserAttributes
