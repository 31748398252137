import Vue from 'vue'
import { Auth } from 'aws-amplify'
import VueRouter from 'vue-router'
import ViewLogin from '../views/ViewLogin.vue'
import ViewForgotPassword from '../views/ViewForgotPassword.vue'
import InvoiceRoutes from '../views/invoices/routes'
import TransportationDocumentsRoutes from '../views/transportationDocument/routes'
import OccurrencesRoutes from '../views/occurrences/routes'
import ViewListUser from '../views/user/ViewListUser.vue'
import ViewFormUser from '../views/user/ViewFormUser.vue'
import ViewFormMassDelivery from '../views/mass-delivery/ViewFormMassDelivery.vue'
import CompaniesRoutes from '../views/companies/routes'
import ViewListInvoicesMobile from '../views/invoices-mobile/ViewListInvoicesMobile.vue'
Vue.use(VueRouter)

const pathUser = '/user'
const namePathUser = 'user'
const routes = [
  {
    path: '/',
    name: 'home',
    redirect: { name: 'invoices' },
    meta: { requiresAuth: true },
  },
  {
    path: '/login',
    name: 'login',
    component: ViewLogin,
    meta: { requiresNoAuth: true },
  },
  {
    path: '/forgot-password',
    name: 'forgotPassword',
    component: ViewForgotPassword,
    meta: { requiresNoAuth: true },
  },

  {
    path: `${pathUser}`,
    name: `${namePathUser}`,
    component: ViewListUser,
    meta: {
      requiresAuth: true,
      requiresGroup: 'admin',
    },
  },
  {
    path: `${pathUser}/add`,
    name: `${namePathUser}Add`,
    component: ViewFormUser,
    meta: {
      requiresAuth: true,
      requiresGroup: 'admin',
    },
  },
  {
    path: `/mass-delivery`,
    name: `massDelivery`,
    component: ViewFormMassDelivery,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: `${pathUser}/:id`,
    name: `${namePathUser}Update`,
    component: ViewFormUser,
    props: true,
    meta: {
      requiresAuth: true,
      requiresGroup: 'admin',
    },
  },
  {
    path: '/mobile-invoices',
    name: 'mobileInvoices',
    component: ViewListInvoicesMobile,
    meta: {
      requiresAuth: true,
    },
  },
  ...InvoiceRoutes,
  ...TransportationDocumentsRoutes,
  ...OccurrencesRoutes,
  ...CompaniesRoutes,
]

const router = new VueRouter({
  routes,
})

router.beforeResolve(async (to, from, next) => {
  //Valida se o usuário precisa estar autenticado para acessar a página
  if (to.meta?.requiresAuth) {
    try {
      let user = await Auth.currentAuthenticatedUser()
      //Valida se o usuário autenticado está no grupo de permissão para acessar a página
      if (to.meta?.requiresGroup && user.attributes.profile !== to.meta.requiresGroup) {
        next({
          path: '/',
        })
      }
      next()
    } catch {
      next({
        path: '/login',
      })
    }
  }
  if (to.meta?.requiresNoAuth) {
    try {
      await Auth.currentAuthenticatedUser()
      next({
        path: '/',
      })
    } catch {
      next()
    }
  }
  next()
})

export default router
