<template>
  <div class="noWrap">
    {{ options.transform(value, row) }}
  </div>
</template>

<script>
export default {
  name: 'DataTableTransformValueColumn',
  props: {
    value: {
      type: [String, Boolean, Array, Number],
      default: '',
    },
    options: {
      type: Object,
      default() {
        return {
          // eslint-disable-next-line no-unused-vars
          transform: (value, row) => value,
        }
      },
    },
    row: {
      type: Object,
      default() {
        return {}
      },
    },
  },
}
</script>
