class DataTableService {
  static saveFilters(crudListName, query) {
    localStorage.setItem(`${crudListName}Filtros`, JSON.stringify(query))
  }

  static getFilters(crudListName) {
    return JSON.parse(localStorage.getItem(`${crudListName}Filtros`))
  }

  static saveHeaders(crudListName, query) {
    const headers = query.map((column) => ({
      text: column.text,
    }))
    localStorage.setItem(`${crudListName}Headers`, JSON.stringify(headers))
  }

  static getHeaders(crudListName) {
    return JSON.parse(localStorage.getItem(`${crudListName}Headers`))
  }
}

export default DataTableService
