const name = 'transportationDocument'
const path = '/transportation-documents'

export default [
  {
    path: `${path}`,
    name: `${name}`,
    component: () => import('./ViewListTransportationDocument.vue'),
    meta: {
      requiresAuth: true,
    },
  },
]
