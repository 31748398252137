import config from '@/config'
import AWS from 'aws-sdk'
export default {
  simplifyArrayOfObjects: (arr) => {
    return Object.assign({}, ...arr)
  },
  readFile: (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsArrayBuffer(file)
      reader.onload = (evt) => {
        resolve(new Uint8Array(evt.target.result))
      }
      reader.onerror = (error) => reject(error)
    }),
  convertToBase64: (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(new Blob([file]))
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  },
  s3: () => {
    const s3 = new AWS.S3({
      accessKeyId: config.AWS_S3_TEXTRACT_BUCKET_KEY,
      secretAccessKey: config.AWS_S3_TEXTRACT_BUCKET_SECRET_KEY,
    })
    return s3
  },

  makeDefaultRequestData: () => {
    return {
      baseUrl: config.API_GATEWAY_URL,
      headers: {
        Authorization: `Bearer ${localStorage.getItem(config.ID_TOKEN_STORAGE)}`,
        encoding: 'gzip',
      },
    }
  },
  makeDefaultMutipartPostData: () => {
    return {
      baseUrl: config.API_GATEWAY_URL,
      headers: {
        Authorization: `Bearer ${localStorage.getItem(config.ID_TOKEN_STORAGE)}`,
        'Content-Type': 'multipart/form-data',
      },
    }
  },
  makeUserManagerRequestData: () => {
    return {
      baseUrl: config.USER_MANAGER_BASE_URL,
      headers: {
        Authorization: `Bearer ${localStorage.getItem(config.TOKEN_STORAGE)}`,
      },
    }
  },
  makeBackendRequestData: () => {
    return {
      baseUrl: config.BACKEND_URL,
      headers: {
        Authorization: `Bearer ${localStorage.getItem(config.ID_TOKEN_STORAGE)}`,
      },
    }
  },

  makeDefaultRequestMuleData: () => {
    return {
      baseUrl: `${config.BACKEND_URL}/api`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem(config.ID_TOKEN_STORAGE)}`,
        encoding: 'gzip',
      },
    }
  },
  makeDefaultRequestLambdaUrlMuleData: () => {
    return {
      baseUrl: config.MULE_INVOICE_LAMBDA_URL,
      headers: {
        Authorization: `Bearer ${localStorage.getItem(config.ID_TOKEN_STORAGE)}`,
        encoding: 'gzip',
      },
    }
  },

  makeTextractRequestData: () => {
    return {
      baseUrl: config.AWS_TEXTRACT_SERVICE,
      headers: {
        Authorization: `Bearer ${localStorage.getItem(config.ID_TOKEN_STORAGE)}`,
      },
    }
  },

  makeDanfeServiceRequestData: () => {
    return {
      baseUrl: config.DANFE_SERVICE_URL,
      headers: {
        Authorization: `Bearer ${localStorage.getItem(config.ID_TOKEN_STORAGE)}`,
      },
    }
  },
  diffArray: (arr1, arr2) => {
    var newArr = []

    newArr = arr1.concat(arr2)

    function checkNum(num) {
      if (arr1.indexOf(num) === -1 || arr2.indexOf(num) === -1) {
        return num
      }
    }

    return newArr.filter(checkNum)
  },
}
