import Request from 'plugin-tigre-request'
import utils from '@/utils'
import config from '@/config'
import UserAttributes from '@/helpers/UserAttributes'

const defaultRequestParam = () => {
  return {
    ...utils.makeUserManagerRequestData(),
    resource: `/userPool/${config.AWS_ID_GROUP_USER_POOL}/user`,
  }
}

const companyCodeRequestParam = () => {
  return {
    ...utils.makeDefaultRequestData(),
    resource: `/transport-co/users`,
  }
}

const PromiseReturn = (requestParam) => {
  return new Promise((resolve, reject) => {
    Request(requestParam)
      .then((response) => {
        resolve(response)
      })
      .catch(({ response }) => {
        reject(response)
      })
  })
}

const types = {
  DATA_TO_LIST: 'setDataToList',
  DATA_TO_CHANGE: 'setData',
  CLEAR_STATE: 'clearState',
  DATA_COMPANY_CODE_TO_LIST: 'setCompanyCodeDataToList',
  FILTER: 'setFilter',
  PAGINATION_TOKEN: 'setPaginationToken',
}
export default {
  namespaced: true,
  state: {
    list: [],
    data: {},
    filters: {},
    companyCodeList: [],
    PaginationToken: null,
  },
  getters: {
    getList(state) {
      return state.list
    },
    getCompanyCodeList(state) {
      return state.companyCodeList
    },
    getPaginationToken(state) {
      return state.PaginationToken
    },
    getData(state) {
      let data = {
        ...state.data,
      }
      data['id'] = data?.Username
      data['active'] = data?.Enabled
      return data
    },
    getListFilter(state) {
      let list = state.list
      const keys = Object.keys(state.filters)

      keys.forEach((key) => {
        list = list.filter((item) => (state.filters[key] != null ? item[key]?.includes(state.filters[key]) : item))
      })

      return list
    },
  },
  mutations: {
    setDataToList(state, payload) {
      const { data } = payload
      let newFormatList = data.map((user) => {
        user['id'] = user.Username
        user['active'] = user.Enabled
        return user
      })
      state.list = [...state.list, ...newFormatList]
    },
    setCompanyCodeDataToList(state, payload) {
      const { data } = payload
      state.companyCodeList = data
    },
    setData(state, payload) {
      const { data } = payload
      state.data = data
    },
    clearState(state) {
      state.list = []
      state.data = {}
      state.companyCodeList = []
      state.PaginationToken = null
    },
    setFilter(state, payload) {
      state.filters = {
        ...payload,
      }
    },
    setPaginationToken(state, payload) {
      state.PaginationToken = payload
    },
  },
  actions: {
    setAddCompanyCode(_, data) {
      let requestParam = {
        ...companyCodeRequestParam(),
        data: data,
        method: 'POST',
      }
      return PromiseReturn(requestParam)
    },
    setAddData(_, data) {
      let requestParam = {
        ...defaultRequestParam(),
        data: data,
        method: 'POST',
      }
      return PromiseReturn(requestParam)
    },
    setUpdateData(_, data) {
      let id = data.id
      delete data.id
      let requestParam = {
        ...defaultRequestParam(),
        data: data,
        method: 'PUT',
        path: `/${id}`,
      }
      return PromiseReturn(requestParam)
    },
    setDeleteData(_, data) {
      let requestParam = {
        ...defaultRequestParam(),
        method: 'DELETE',
        path: `/${data.id}`,
      }
      return PromiseReturn(requestParam)
    },
    setData({ commit }, data) {
      let requestParam = {
        ...defaultRequestParam(),
        method: 'GET',
        path: `/${data.id}`,
      }
      return new Promise((resolve, reject) => {
        Request(requestParam)
          .then((response) => {
            commit(types.DATA_TO_CHANGE, {
              data: response.data.data,
              request: requestParam,
            })
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    setDataToList({ commit }, data) {
      data = {
        ...data,
        attributes: 'name,email,profile',
      }
      let requestParam = {
        ...defaultRequestParam(),
        data: data,
        method: 'GET',
      }
      return new Promise((resolve, reject) => {
        Request(requestParam)
          .then((response) => {
            commit(types.DATA_TO_LIST, {
              data: response.data.data,
              request: requestParam,
            })

            commit(types.PAGINATION_TOKEN, response.data.PaginationToken)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    setCompanyCodeDataToList({ commit }, data) {
      let requestParam = {
        ...companyCodeRequestParam(),
        method: 'GET',
        path: `/${data.email}`,
      }
      return new Promise((resolve, reject) => {
        Request(requestParam)
          .then((response) => {
            commit(types.DATA_COMPANY_CODE_TO_LIST, {
              data: response.data,
              request: requestParam,
            })
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    async setCompanyCodeDataToListWs({ commit }, data) {
      var connection = null
      data.email = 'giovani.vieira@mouts.info'
      const email = await UserAttributes('email')
      const message = {
        action: 'default',
        path: `/api/transport-co/users/${data.email}`,
        email,
      }
      console.log(message)
      return new Promise((resolve, reject) => {
        connection = new WebSocket('wss://lmj9j2jxoc.execute-api.us-east-1.amazonaws.com/dev')
        connection.onopen = () => {
          console.log('Conexão aberta')
          connection.send(JSON.stringify(message))
        }
        connection.onmessage = (event) => {
          console.log(JSON.parse(event.data))
          const eventData = JSON.parse(event.data).data

          commit(types.DATA_COMPANY_CODE_TO_LIST, {
            data: eventData,
          })
          connection.close()
          resolve(event)
          if (event.error) reject(event)
        }
      })
    },
    setFilter({ commit }, data) {
      commit(types.FILTER, data)
    },
    clearState({ commit }) {
      commit(types.CLEAR_STATE)
    },
  },
}
