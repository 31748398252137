export default {
  props: {
    value: {
      type: [String, Number],
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '__/__/____',
    },
    prependIcon: {
      type: String,
      default: '',
    },
    rules: {
      type: Array,
      default() {
        return []
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    format: {
      type: String,
      default: '',
    },
    allowedDates: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      menuDate: false,
      date: '',
      dateFormatBr: 'DD/MM/YYYY',
    }
  },
  computed: {
    getRules() {
      const newRulesArray = []
      newRulesArray.push(this.isValidDate())
      return newRulesArray.concat(this.rules)
    },
    computedDateFormatted: {
      get() {
        if (this.value) {
          return this.formatDate(this.value)
        }
        return ''
      },
      set(value) {
        if (value && value.length === 8) {
          const formatedDate = this.dateTransform(value)

          if (this.formatDate(formatedDate) !== this.date) {
            this.datePicker = formatedDate
            this.handleDate(formatedDate)
          }
        }
      },
    },
    getValue: {
      get() {
        const regex = /^\d{4}-\d{2}-\d{2}$/m
        if (regex.exec(this.value) === null) {
          return null
        }
        return this.value
      },
      set(value) {
        if (this.formatDate(value) !== this.date) {
          const dateToNumber = this.transformDateToNumber(value)
          this.$emit('input', this.format ? this.$moment(dateToNumber).format(this.format) : dateToNumber)
        }
      },
    },
  },
  methods: {
    clear() {
      this.$emit('input', '')
    },
    allowedDatesMethod(val) {
      if (this.allowedDates.length <= 0) {
        return true
      }
      return this.allowedDates.indexOf(val) !== -1
    },
    handleDate(date) {
      this.date = this.formatDate(date)
    },
    formatDate(date) {
      return this.$moment(date, this.format || 'YYYY-MM-DD').format(this.format || this.dateFormatBr)
    },
    dateTransform(value) {
      return value.replace(/(\d{2})(\d{2})(\d{4})/, '$3-$2-$1')
    },
    transformDateToNumber(value) {
      return value.replace(/(\d{4})(\d{2})(\d{2})/, '$3$2$1')
    },
    isValidDate() {
      return (value) => {
        if (!value) return true
        const timeValue = this.$moment(value, this.dateFormatBr)
        if (!timeValue.isValid()) {
          return this.$t('invalidDate')
        }
        return true
      }
    },
  },
}
