export default {
  example: 'Exemplo',
  exit: 'Sair',
  itemsPerPage: 'Registros por página',
  invoices: "NFe's",
  invoicesOccurrences: "NFe's / Ocorrências",
  invoicesList: "NFe's / Lançamento de Ocorrências",
  invoice: 'NFe',
  date: 'Data',
  receiver: 'Destinatário',
  destination: 'Destino',
  lastOccurrence: 'Última ocorrência',
  wasDelivered: 'Entregue',
  actions: 'Ações',
  period: 'Referência',
  invoiceNumber: 'N° NFe',
  filters: 'Filtros',
  registerOccurrence: 'Registrar ocorrência',
  seeStub: 'Ver canhoto',
  loadOccurrences: 'Carregar ocorrências',
  sortBy: 'Ordernar por',
  scheduledPayment: 'Pagamento Realizado',
  origin: 'Origem',
  transportationNumber: 'Número do CTE',
  transportationCode: 'CTe',
  transportationCodes: "CTe's",
  percentInvoicesDelivered: '% de NF entregues',
  paymentForecast: 'Previsão de pagamento',
  monetaryValue: 'Valor R$',
  yes: 'Sim',
  no: 'Não',
  paid: 'Pago',
  occurrences: 'Ocorrências',
  occurrence: 'Ocorrência',
  status: 'Status',
  time: 'Hora',
  file: 'Arquivo',
  reason: 'Motivo',
  monitor: 'Monitor - Log de Ocorrências',
  unprocessed: 'Não processado',
  expectedDate: 'Data prevista',
  invalidHour: 'A hora informada não é válida.',
  invalidDate: 'A data informada não é válida.',
  addOccurrence: 'Adicionar ocorrência',
  cancel: 'Cancelar',
  save: 'Salvar',
  requiredMessage: 'Este campo é obrigatório.',
  dateMustBeEqualOrGreaterThanCurrent: 'A data selecionada deve ser maior ou igual à data atual.',
  transportationDocuments: "CTe's",
  user: 'Usuários',
  updateUser: 'Atualizar dados de usuário',
  name: 'Nome',
  profile: 'Perfil',
  email: 'E-mail',
  required: 'Obrigatório',
  SAPcode: 'Código SAP',
  ok: 'Ok',
  EmailMustBeValid: 'E-mail inválido',
  external_id_sap: 'Código externo - SAP',
  active: 'Ativo',
  anUnexpectedErrorHasOccurred: 'Um erro inesperado ocorreu',
  noDataAvailable: 'Nenhum registro foi encontrado.',
  confirmNewPassword: 'Confirmar senha',
  password: 'Senha',
  forgotPassword: 'Esqueceu a senha?',
  newPassword: 'Nova senha',
  signIn: 'Entrar',
  incorrectUsernameOrPassword: 'Usuário ou senha incorretos',
  minimumLength: 'A senha deve ter no mínimo 6 dígitos.',
  passwordNotMatch: 'A confirmação da senha deve ser igual.',
  fileProcessingConcluded: 'Processamento do arquivo concluído',
  fileUploadConcluded: 'Arquivo(s) em processamento, verificar Log de ocorrências em alguns minutos.',
  fileUploadConcludedWithFilesUnsubmitted:
    'Arquivo(s) em processamento, verificar Log de ocorrências em alguns minutos. Arquivos sem notas detectadas: {files}',
  message: 'Mensagem',
  processed: 'Processado?',
  issueDate: 'Data de emissão',
  logDate: 'Data Registro',
  logTime: 'Hora Registro',
  occurrenceDate: 'Data Ocorrência',
  occurrenceTime: 'Hora Ocorrência',
  invoiceItems: 'Itens da Nota Fiscal',
  materialCode: 'Código do material',
  materialDescription: 'Material',
  amount: 'Quantidade',
  unitOfMeasurement: 'Unidade de Medida',
  close: 'Fechar',
  of: 'de',
  issuerCompanyCode: 'CNPJ transportadora',
  invoicesIssuerCompanyCode: 'CNPJ Emissor',
  transportCompanyCode: 'CNPJ Transportadora',
  carrier: 'Transportadora',
  transportCity: 'Cidade Transportadora',
  businessUnit: 'Centro Emissor',
  schedulingDate: 'Data Agendamento',
  deliveryForecastDate: 'Previsão de entrega',
  incoterms: 'Tipo de Frete',
  confirmOccurrencesUpload: 'Deseja realizar o enviar do arquivo de ocorrências?',
  exportSuccessfullyCompleted: 'Exportação realizada com sucesso!',
  confirmDownloadExcelFile: 'Deseja exportar os dados para download?',
  occurrenceSent: 'Ocorrência registrada com sucesso',
  redispatch: 'Redespacho',
  doYouReallyWantToContinue: 'Deseja confirmar esta ação?',
  savedSuccessfully: 'Ação realizada com sucesso!',
  waiting: 'Aguarde...',
  companyCode: 'Código da empresa (CNPJ)',
  userDoesNotExist: 'Dados incorretos',
  'An account with the given email already exists.': 'Uma conta com este e-mail já existe.',
  transportDocumentType: 'Tp. Doc. Transporte',
  docNum: 'DOCNUM',
  apply: 'Aplicar filtro',
  filterNotApplied: '* Existem filtros não aplicados',
  documentNumber: 'Documento de transporte(DT)',
  clearingDocument: 'Documento de compensação',
  accKey: 'Chave CTe',
  paymentDate: 'Data de Pagamento',
  dateIntervalMustBeLessOrEqualThan: 'O intervalo entre as datas deve ser inferior ou igual a {days} dias',
  finalDateMustBeBiggerThanInitialDate: 'Data final deve ser superior à data inicial',
  startTime: 'Data inicial',
  finishTime: 'Data final',
  estimatedDate: 'Data estimada de entrega',
  updatedDate: 'Data de agendamento',
  companiesConfig: 'Configuração - Empresas',
  edit: 'Editar',
  add: 'Novo',
  uploadStub: 'Enviar canhoto',
  stubs: 'Canhotos',
  creationStartTime: 'Data Emissão NFe - Início',
  creationFinishTime: 'Data Emissão NFe - Fim',
  cteNumber: 'Número do Cte',
  nfNumber: 'Nota fiscal',
  emmiterCode: 'CNPJ Emissor',
  docNumber: 'Documento de transporte',
  uploadVouchers: 'Lançar comprovantes',
  resetPassword: 'Recuperar senha',
  extension: 'Extensão',
  confirmFileUpload: 'Deseja realizar o enviar do arquivo?',
  fileUploadStubConcluded: 'Arquivo enviado com sucesso!',
  cteKey: 'Chave do CTe',
  compNumber: 'Nº Doc. de Compensação',
  altStartTime: 'Data de inicio da alteração',
  altFinishTime: 'Data de termino da alteração',
  contNumber: 'Documento contábil',
  sapCode: 'Código fornecedor SAP',
  link: 'Link',
  massDelivery: 'Envio de comprovante de entrega em massa',
  cnpj: 'CNPJ',
  chooseFiles: 'Selecionar arquivos para envio',
  sendFiles: 'Enviar arquivos',
  confirmAmountOfNotes: 'Foram detectadas {amount} notas, deseja realizar o envio?',
  dontCloseWindow: 'Não feche esta aba do navegador',
  fileNamesDisclaimer:
    'Selecione quantos arquivos quiser. PDFs com mais de um página serão quebrados em arquivos com o prefixo Pagina_. Também será adicionado um prefixo ao seu arquivo para garantir a integridade no envio.',
  filesToSend: 'Arquivos para enviar',
  noNumbersDetected: "Não foram detectadas NFe's válidas",
  from: 'De',
  to: 'Até',
}
