<script>
import ListView from '@/components/ListView'

export default {
  extends: ListView,
  name: 'CrudListCompanyCodes',
  props: {
    details: {
      type: [Object, Array],
      default() {
        return []
      },
    },
  },
  data() {
    return {
      namePage: this.$i18n.t('orderItems'),
      path: 'user',
      headerContent: () => import('./ListCompanyCodesHeaderComponent.vue'),
      hideDefaultActions: true,
      addNew: false,
      dataProperties: { path: 'user', namePage: this.$i18n.t('orderItems') },
      headers: [
        {
          text: this.$i18n.t('email'),
          align: 'start',
          sortable: true,
          value: 'e-mail',
        },
        {
          text: this.$i18n.t('companyCode'),
          align: 'start',
          sortable: true,
          value: 'companyCode',
        },
      ],
    }
  },
  methods: {
    async getItemsList() {
      return true
    },
  },
  computed: {
    getListFilter() {
      return this.details
    },
  },
}
</script>
