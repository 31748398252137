export default {
  methods: {
    async mixinAlertConfirm(msg) {
      return this.$swal('confirm', {
        title: msg || this.$i18n.t('doYouReallyWantToContinue'),
        buttonCancel: {
          text: this.$i18n.t('cancel'),
        },
        buttonOk: {
          text: this.$i18n.t('ok'),
        },
      })
    },
    async mixinAlertError(msg) {
      return this.$swal('error', {
        title: msg || this.$i18n.t('anUnexpectedErrorHasOccurred'),
        buttonOk: {
          text: this.$i18n.t('ok'),
        },
      })
    },
    async mixinAlertSuccess(msg) {
      return this.$swal('success', {
        title: msg || this.$i18n.t('savedSuccessfully'),
        buttonOk: {
          text: this.$i18n.t('ok'),
        },
      })
    },
    async mixinAlertLoading(title) {
      return this.$swal('loading', {
        loading: {
          text: title || this.$i18n.t('waiting'),
        },
      })
    },
  },
}
